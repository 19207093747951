import { ref } from 'vue'
// import {ref} from "@vue/composition-api";
import RuleDetail from '@/utils/yo-validator/fields/RuleDetail'
import IdGenerator from '@/utils/yo-validator/IdGenerator'
import EventManager from '@/utils/yo-validator/EventManager'

const EVENT_INVALID_FIELD = 'INVALID_FIELD'
const TYPE_EMAIL = 'email'
const RULE_REQUIRED = 'required'

export default class BaseRuleField {
  constructor({ id, formId, fieldName, rules, type }) {
    this.id = id
    this.formId = formId
    this.type = type
    this.fieldName = fieldName
    this.formData = {}
    this.isRequired = false
    this.rules = this.modifyRules(rules)
    this.ruleDetailsList = {}
    this.setFieldRuleDetailsList()
    this.errorMessages = []
    this.target = {}
    this.isPristine = ref(true)
    this.isDirty = ref(false)
    this.isValid = ref(!this.isRequired)
    this.isInvalid = ref(this.isRequired)
  }

  modifyRules(rules) {
    if (this.type && this.type.toLowerCase() === TYPE_EMAIL) {
      rules = rules.length === 0 ? TYPE_EMAIL : rules.includes(TYPE_EMAIL) ? rules : `${rules}|${TYPE_EMAIL}`
    }
    // here rules can be undefined or null
    return !rules ? '' : rules
  }

  setFieldRuleDetailsList() {
    this.isRequired = this.rules.includes(RULE_REQUIRED)
    const ruleDetails = this.rules.split('|')
    // populate each rule details
    ruleDetails.map(details => this.addRuleDetails(details))
  }

  addRuleDetails(details) {
    const detailsId = IdGenerator.getId()
    this.ruleDetailsList[detailsId] = new RuleDetail({
      id: detailsId,
      formId: this.formId,
      fieldId: this.id,
      fieldName: this.fieldName,
      details,
      isRequired: this.isRequired
    })
  }

  setFieldData({ formData }) {
    /** here keep a reference to the entire form data also for password confirm, the rule checks 2 values **/
    this.formData = formData
    this.isPristine.value = false
    this.isDirty.value = true
  }

  validateRules() {
    return this.validate(this.formData)
  }

  validateRulesByData(parsedData) {
    return this.validate(parsedData)
  }

  validate(data) {
    this.resetErrors()
    let allRulesValid = true
    // loop through rules
    for (const ruleDetailsKey in this.ruleDetailsList) {
      if (this.ruleDetailsList.hasOwnProperty(ruleDetailsKey)) {
        const ruleDetails = this.ruleDetailsList[ruleDetailsKey]
        // validate each single rule, e.g. length: 8
        if (!ruleDetails.validateRule(data, this.addErrorMessage.bind(this))) {
          allRulesValid = false
        }
      }
    }
    this.isValid.value = allRulesValid
    this.isInvalid.value = !allRulesValid
    this.notifyErrorField()
    return allRulesValid
  }

  notifyErrorField() {
    EventManager.emit(EVENT_INVALID_FIELD, { id: this.id, messages: this.getErrorMessages() })
  }

  addErrorMessage(message) {
    if (!this.errorMessages.includes(message)) {
      this.errorMessages.push(message)
    }
  }

  getErrorMessages() {
    return [...new Set(this.errorMessages)]
  }

  resetErrors() {
    this.errorMessages = []
  }
}
