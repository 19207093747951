import BaseRule from '@/utils/yo-validator/rules/BaseRule'

export default class RuleMax extends BaseRule {
  constructor() {
    super()
  }

  validate(fieldName, formData, maxValue) {
    if (maxValue === undefined || maxValue === null) return true
    const fieldData = formData[fieldName]
    return parseFloat(fieldData) <= parseFloat(maxValue)
  }

  parseMessage(message, ruleName, ruleLimit) {
    return super.parseStandardMessage(message, ruleName, ruleLimit)
  }
}