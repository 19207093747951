import BaseRuleField from '@/utils/yo-validator/fields/BaseRuleField'

export default class AggressiveRuleField extends BaseRuleField {
  constructor({ id, formId, fieldName, rules, type }) {
    super({ id, formId, fieldName, rules, type })
    this.listeners = {}
  }

  setFieldData({ formData }) {
    super.setFieldData({ formData })
    super.validateRules()
  }
}