import { ref } from 'vue'
// import {ref} from "@vue/composition-api";
import { MODES } from '@/utils/yo-validator/forms/FormManager'
import BaseRuleField from '@/utils/yo-validator/fields/BaseRuleField'
import LazyRuleFieldProgress from '@/utils/yo-validator/fields/LazyRuleFieldProgress'

export default class BaseForm {
  constructor(formId, mode, name, onFormDataUpdate) {
    this.id = formId
    this.mode = mode
    this.name = name
    this.fields = {}
    this.formData = {}
    this.onFormDataUpdate = onFormDataUpdate
    this.isPristine = ref(true)
    this.isDirty = ref(false)
    this.isValid = ref(true)
    this.isInvalid = ref(false)
  }

  setFormData({ fieldName, data }) {
    // update the entire form data
    this.formData = Object.assign({}, this.formData, { [fieldName]: data })
    this.isPristine.value = false
    this.isDirty.value = true
    // update parent if needed
    if (this.onFormDataUpdate) {
      this.onFormDataUpdate(this.formData)
    }
  }

  addRuleField({ fieldId, formId, fieldName, rules, type }) {
    if (this.mode === MODES.LAZY) {
      this.fields[fieldId] = new LazyRuleFieldProgress({ id: fieldId, formId, fieldName, rules, type })
    } else {
      this.fields[fieldId] = new BaseRuleField({ id: fieldId, formId, fieldName, rules, type })
    }
    return { field: this.fields[fieldId] }
  }

  removeRuleField(fieldId) {
    delete this.fields[fieldId]
  }

  validateField(fieldId) {
    const ruleField = this.fields[fieldId]
    if (ruleField) {
      ruleField.validateRules()
      this.isValid.value = this.checkFormValid()
      this.isInvalid.value = !this.isValid.value
    }
  }

  checkFormValid() {
    for (const fieldKey in this.fields) {
      if (this.fields.hasOwnProperty(fieldKey)) {
        const field = this.fields[fieldKey]
        if (!field.isValid.value) {
          return false
        }
      }
    }
    return true
  }

  validateFormFields() {
    let isFormValid = true
    for (const fieldId in this.fields) {
      if (this.fields.hasOwnProperty(fieldId)) {
        const ruleField = this.fields[fieldId]
        // validate rules, e.g. required|length:8|size:5
        if (!ruleField.validateRules()) {
          isFormValid = false
        }
      }
    }
    this.isValid.value = isFormValid
    this.isInvalid.value = !isFormValid
    return { isFormValid, formData: this.formData }
  }

  validateFieldByData(fieldId, dataValue) {
    const ruleField = this.fields[fieldId]
    if (ruleField) {
      // make it like a formData format -> key value pair
      const parsedData = { [ruleField.fieldName]: dataValue }
      ruleField.validateRulesByData(parsedData)
    }
  }

  resetFieldErrors() {
    for (const fieldId in this.fields) {
      if (this.fields.hasOwnProperty(fieldId)) {
        const ruleField = this.fields[fieldId]
        ruleField.resetErrors()
      }
    }
  }
}
