import BaseForm from '@/utils/yo-validator/forms/BaseForm'
import { MODES } from '@/utils/yo-validator/forms/FormManager'

export default class LazyForm extends BaseForm {
  constructor(formId, mode, name, onFormDataUpdate) {
    super(formId, mode, name, onFormDataUpdate)
    this.mode = MODES.LAZY
  }

  setFormData({ fieldId, fieldName, data, target }) {
    super.setFormData({ fieldId, fieldName, data, target })
    // also set data in the field
    this.fields[fieldId].setFieldData({ form: this, target })
  }
}
