import BaseRule from '@/utils/yo-validator/rules/BaseRule'

export default class RulePasswordUpperCase extends BaseRule {
  constructor() {
    super()
  }

  validate(fieldName, formData) {
    if (!formData || !fieldName) return false
    // deal with the case of confirm
    // if (limit && limit.charAt(0) === CHAR_CONFIRM) {
    //   return formData[fieldName] === formData[limit.substring(1)];
    // }
    // check regular password
    return this.isPasswordStrong(formData[fieldName])
  }

  isPasswordStrong(password) {
    return (password.match(/[A-Z]+/))
  }
}
