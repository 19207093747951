import BaseForm from '@/utils/yo-validator/forms/BaseForm'
import { MODES } from '@/utils/yo-validator/forms/FormManager'

export default class SubmitForm extends BaseForm {
  constructor(formId, mode, name, onFormDataUpdate) {
    super(formId, mode, name, onFormDataUpdate)
    this.mode = MODES.SUBMIT
  }

  setFormData({ fieldId, fieldName, data, target }) {
    super.setFormData({ fieldId, fieldName, data, target })
    // also set data in the field
    this.fields[fieldId].setFieldData({ formData: this.formData })
  }
}