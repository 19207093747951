export class EventManager {
  constructor() {
    this.events = {}
  }

  static getInstance() {
    if (!this.instance) {
      this.instance = new EventManager()
    }
    return this.instance
  }

  subscribe(eventName, fn) {
    this.events[eventName] = this.events[eventName] || []
    this.events[eventName].push(fn)
  }

  unsubscribe(eventName, fn) {
    this.events[eventName] = this.events[eventName].filter(elem => elem === fn)
  }

  emit(eventName, data) {
    if (this.events[eventName]) {
      for (const fn of this.events[eventName]) {
        fn(data)
      }
    }
  }
}

export default new EventManager()
