import BaseRuleField from '@/utils/yo-validator/fields/BaseRuleField'
import { ref } from 'vue'
// import {ref} from "@vue/composition-api";

const EVENT_BLUR = 'blur'

export default class LazyRuleField extends BaseRuleField {
  constructor({ id, formId, fieldName, rules, type, formData, target, isPristine, isDirty, isValid, isInvalid }) {
    super({ id, formId, fieldName, rules, type })
    this.formData = formData
    this.target = target
    this.isPristine = ref(isPristine.value)
    this.isDirty = ref(isDirty.value)
    this.isValid = ref(isValid.value)
    this.isInvalid = ref(isInvalid.value)
    this.addEventHandlers()
  }

  setFieldData({ form }) {
    super.setFieldData({ formData: form.formData })
  }

  setFieldDataByTargetData({ data }) {
    super.setFieldData({ formData: data })
  }

  addEventHandlers() {
    // add handlers to each target (usually 1, for passwordConfirm it's 2)
    for (const targetKey in this.target) {
      if (this.target.hasOwnProperty(targetKey)) {
        const target = this.target[targetKey]
        target.addEventListener(EVENT_BLUR, super.validateRules.bind(this))
      }
    }
  }
}
