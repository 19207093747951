export default {
  names: {
    username: "username",
    email: "e-mailadres",
    numberField: "nummmer",
    profile: "profiel foto",

    // Auth
    password: "wachtwoord",

    // Shared
    name: "naam",
    phone: "telefoonnummer",
    agreeToTerms: "terms",

    // Register Teacher
    teacherType: "type leraar",
    teacherPhone: "telefoonnummer leraar",
  },
  messages: {
    required: {
      default: "De {fieldName} is verplicht.",

      // Shared
      name: "Er dient een naam te worden opgegeven.",
      agreeToTerms:
        "Om een account te maken, moet je akkoord gaan met onze gebruiksvoorwaarden.",
      email: "Er dient een e-mailadres te worden opgegeven.",
      password: "Er dient een wachtwoord te worden opgegeven.",
      currentPassword: "Er dient een wachtwoord te worden opgegeven.",
      confirmation: "Er dient een herhaling te worden opgegeven.",
      firstName: "Er dient een voornaam te worden opgegeven.",
      lastName: "Er dient een achternaam te worden opgegeven.",
      streetName: "Er dient een straatnaam te worden opgegeven.",
      houseNumber: "Er dient een huisnummer te worden opgegeven.",
      brin: "Er dient een BRIN-nummer te worden opgegeven.",
      postalCode: "Er dient een postcode te worden opgegeven.",
      city: "Er dient een plaatsnaam te worden opgegeven.",
      province: "Er dient een provincie te worden opgegeven.",
      schoolTypeName: "Er dient een type onderwijs te worden opgegeven.",
      title: "Voer de titel in.",
      bits: "Selecteer een aantal bits.",
      video: "Voer de video url in.",
      dayOfMM: "Voer in op welke dag van de week deze missie plaats vind.",

      // Register Teacher
      teacherType: "Het type leraar dient te worden opgegeven.",
      teacherPhone: "Geef een geldig Nederlands telefoonnummer op.",

      // Create/Edit Class
      teachers: "De leraar is vereist.",
      group: "De groep is vereist.",
      totalStudents: "Het aantal leerlingen is vereist.",
      studentCount: "Het aantal leerlingen is vereist.",
      classTypeName: "De groep is vereist.",

      // FAQ
      question: "Voer vraag in.",
      answer: "Voer antwoord in.",
      "answers[0]": "Voer antwoord in.",
      "answers[1]": "Voer antwoord in.",
      "answers[2]": "Voer antwoord in.",
      "answers[3]": "Voer antwoord in.",
      "answers[4]": "Voer antwoord in.",

      // Missions
      statement: "De verklaring is verplicht",
      positiveAnswer: "Het positieve antwoord is verplicht.",
      negativeAnswer: "Het negatieve antwoord is verplicht",
      neutralAnswer: "Het neutrale antwoord is verplicht",
      wordToReveal: "Het woord om te onthullen is verplicht",
      articleTitle: "De artikelen titel is verplicht.",
      articleContent: "Voer de 'over' in.",
      subject: "Het onderwerp is verplicht.",

      // Prizes
      prizeTitle: "Voer de title in.",
      prizeDescription: "Voer de omschrijving in.",
      prizePhoto: "Voeg een omslagfoto toe.",

      //ExtraGame
      extraName: "Voer de title in.",
      extraDescription: "Voer de omschrijving in.",
      extraLink: "Voer de link van de partner in.",
      bitsAwarded: "Voer het aantbal bits in.",
    },
    alpha: {
      default: "Uw {fieldName} mag alleen alfabetten bevatten.",
    },
    alphaNumeric: {
      default: "Uw {fieldName} mag alleen letters en cijfers bevatten.",
    },
    numeric: {
      default: "Uw {fieldName} mag alleen cijfers bevatten.",
    },
    email: {
      default: "Uw {fieldName} is geen geldig e-mailadres.",
    },
    ext: {
      default: "De extensie van uw {fieldName} mag alleen {ext} zijn.",
    },
    max: {
      default: "Uw {fieldName} mag niet groter zijn dan {max}.",
    },
    min: {
      default: "Uw {fieldName} mag niet kleiner zijn dan {min}.",
    },
    maxLength: {
      default: "Uw {fieldName} mag niet langer zijn dan {maxLength} tekens.",
    },
    minLength: {
      default: "Uw {fieldName} mag niet korter zijn dan {minLength} tekens.",
    },
    maxSize: {
      default: "Uw {fieldName} mag niet groter zijn dan {maxSize}MB.",
    },
    minSize: {
      default: "Uw {fieldName} mag niet kleiner zijn dan {minSize}MB.",
    },
    passwordConfirm: {
      default: "Uw wachtwoorden komen niet overeen.",
    },
    passwordStrength: {
      default: "Het wachtwoord moet uit tenminste 6 tekens bestaan.",
    },
    passwordLength: {
      default: "Een wachtwoord moet uit tenminste 6 tekens bestaan.",
    },
    passwordNumber: {
      default: "Het wachtwoord moet tenminste één cijfer bevatten.",
    },
    passwordUppercase: {
      default: "Het wachtwoord moet tenminste één hoofdletter bevatten.",
    },
    passwordLowercase: {
      default: "Het wachtwoord moet tenminste één kleine letter bevatten.",
    },
    passwordSpecialCharacter: {
      default: "Het wachtwoord moet tenminste één speciaal teken bevatten.",
    },
    dutchPhoneNumber: {
      default: "Geef een geldig Nederlands telefoonnummer op.",
    },
  },
};
