import BaseRule from '@/utils/yo-validator/rules/BaseRule'

const CHAR_CONFIRM = '@'

export default class RulePasswordConfirmation extends BaseRule {
  constructor() {
    super()
  }

  validate(fieldName, formData, limit) {
    if (!formData || !fieldName) return false
    // deal with the case of confirm
    if (limit && limit.charAt(0) === CHAR_CONFIRM) {
      return formData[fieldName] === formData[limit.substring(1)]
    }
    // check regular password
    return !!formData[fieldName]
  }
}