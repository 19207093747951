import BaseRule from '@/utils/yo-validator/rules/BaseRule'

export default class RuleRequired extends BaseRule {
  constructor() {
    super()
  }

  validate(fieldName, formData) {
    const fieldData = formData[fieldName]
    const data = fieldData instanceof FileList ? Array.from(fieldData) : fieldData
    if (Array.isArray(data)) {
      return data.length > 0
    }
    return !!data
  }
}