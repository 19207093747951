export default {
  names: {
    username: 'username',
    email: 'email',
    numberField: 'number',
    profile: 'profile image',

    // Shared
    name: 'name',
    phone: 'phone number',
    agreeToTerms: 'terms',

    // Register Teacher
    teacherType: 'type of teacher',
    teacherPhone: 'phone number of teacher'
  },
  messages: {
    required: {
      default: 'Your {fieldName} is required',

      // Shared
      name: 'Er dient een naam te worden opgegeven.',
      firstName: 'Er dient een voornaam te worden opgegeven.',
      lastName: 'Er dient een achternaam te worden opgegeven.',

      // Register Teacher
      teacherType: 'Het type leraar dient te worden opgegeven.',
      teacherPhone: 'Voer een geldig telefoonnummer in.',
      agreeToTerms: 'Om een account te kunnen aanmaken, dien je akkoord te gaan met onze gebruikersvoorwaarden.'
    },
    alpha: {
      default: 'Your {fieldName} can only contain alphabets'
    },
    alphaNumeric: {
      default: 'Your {fieldName} can only contain alphabets and numbers'
    },
    numeric: {
      default: 'Your {fieldName} can only contain numbers'
    },
    email: {
      default: 'Your {fieldName} is not a valid email'
    },
    ext: {
      default: 'The extension of your {fieldName} can only be {ext}',
      profile: 'You have upload files of correct types - {ext}'
    },
    max: {
      default: 'Your {fieldName} cannot be bigger than {max}'
    },
    min: {
      default: 'Your {fieldName} cannot be smaller than {min}'
    },
    maxLength: {
      default: 'Your {fieldName} cannot be longer than {maxLength} characters'
    },
    minLength: {
      default: 'Your {fieldName} cannot be shorter than {minLength} characters'
    },
    maxSize: {
      default: 'Your {fieldName} cannot be larger than {maxSize}MB'
    },
    minSize: {
      default: 'Your {fieldName} cannot be smaller than {minSize}MB'
    },
    passwordConfirm: {
      default: 'Your passwords do not match'
    },
    passwordStrength: {
      default: 'Wachtwoorden moeten tussen de 6 en 20 tekens lang zijn en minstens één hoofdletter, één kleine letter en één cijfer bevatten'
    }
  }
}
