export default class BaseRule {
  constructor() {
  }

  validateByRegex(fieldName, formData, regex) {
    const fieldData = formData[fieldName]
    return regex.test(fieldData)
  }

  parseMessage(message, ruleName, ruleLimit) {
    return message
  }

  parseStandardMessage(message, ruleName, ruleLimit) {
    if (!ruleName || !ruleLimit) return message
    return message.replace(`{${ruleName}}`, ruleLimit)
  }
}