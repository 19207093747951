import BaseRule from '@/utils/yo-validator/rules/BaseRule'

export default class RuleDutchPhoneNumber extends BaseRule {
  constructor() {
    super()
  }

  validate(fieldName, formData) {
    if (!formData || !fieldName) return false
    const phoneRegex = /^((\+|00(\s|\s?\-\s?)?)31(\s|\s?\-\s?)?(\(0\)[\-\s]?)?|0)[1-9]((\s|\s?\-\s?)?[0-9])((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]$/gm
    return super.validateByRegex(fieldName, formData, phoneRegex)
  }
}