<template>
  <div class="form-wrapper">
    <form ref="form" novalidate @submit.prevent="handleSubmit(sendFormData)">
      <slot name="form-content"/>
      <slot name="button-submit" :is-invalid="isInvalid"/>
    </form>
  </div>
</template>

<script>
import Validator from '@/utils/yo-validator/YoValidator'
import { ref } from '@vue/reactivity'

export default {
  name: 'FormWrapper',
  props: {
    name: {
      required: false
    },
    sendFormData: {
      required: true
    },
    onFormDataUpdate: {
      type: Function,
      required: false
    },
    mode: {
      default: 1
    }
  },
  setup(props) {
    const form = ref(null)
    // get handle submit function
    const { handleSubmit, isInvalid } = Validator.registerForm(props)

    return {
      form,
      handleSubmit,
      isInvalid
    }
  }
}
</script>

<style lang="scss">
@import "../../assets/css/base.variables";
@import "../../assets/css/base.mixins";

$backgroundColor: white;
$titleColor: var(--blue_dark);
$textColor: var(--blue_dark_01);
$placeholderColor: var(--blue_light_01);
$indicatorColor: var(--blue_dark);

/* form elements */
.form-wrapper {
  input, select, textarea {
    width: 100%;
    font-family: var(--font-primary);

    @include placeholder {
      color: $placeholderColor;
      font-family: var(--font-primary);
    }

    &:focus {
      outline: none;
    }
  }

  label {
    display: block;
    width: 100%;
  }

  input {
    &::-webkit-calendar-picker-indicator {
      display: none;
    }
  }

  input[type="date"] {
    &::-webkit-input-placeholder {
      visibility: hidden !important;
    }
  }

  input[type="number"] {
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  }

  textarea {
    resize: none;

    /* set the width to 0.1px so it is not visible but the scrolling function still exists */
    &::-webkit-scrollbar {
      width: 0.1px;
    }
  }
}

.form-group {
  margin-bottom: rem(8);
}

.form-input-title {
  color: $titleColor;
  font-size: rem(16);
  font-weight: 500;
  letter-spacing: 0;
  line-height: rem(19);
  margin-bottom: rem(8);
  text-align: left;
}

.form-input-title-align {
  @extend .form-input-title;
  margin-bottom: rem(20);
  padding-left: 0;
}

.form-input {
  border: none;
  border-radius: rem(8);
  background-color: $backgroundColor;
  box-shadow: rem(2) rem(2) 0 0 rgba(0, 0, 0, 0.1);
  color: $textColor;
  font-size: rem(16);
  height: rem(46);
  padding: rem(11) rem(32) rem(11) rem(53);
}

.form-input-textarea {
  padding: rem(15);
  border: none;
  border-radius: rem(8);
  background-color: $backgroundColor;
  font-size: rem(16);
  color: $textColor;
}

.form-file-select {
  border: none;
  border-radius: rem(8);
  background-color: $backgroundColor;
}

/* texts */
.text-indicator-file {
  padding-left: rem(15);
  color: $indicatorColor;
  font-style: italic;
}

.text-error {
  color: var(--red_error);
  font-size: rem(16);
  letter-spacing: 0;
  line-height: rem(19);
  margin: rem(5) 0;
  text-align: left;
}

.text-error-right {
  @extend .text-error;
  text-align: right;
}

.field-error {
  border: rem(1) solid var(--red_error);
}

.read-only-input {
  @include on-hover {
    cursor: default;
  }
}

.input-icon {
  @include position(absolute, $top: 50%, $left: rem(16));
  height: rem(18);
  transform: translateY(-50%);
  width: rem(18);
}

.input-field-label {
  position: relative;
}

.input-error-icon {
  @include position(absolute, $top: 50%, $right: rem(16));
  height: rem(20);
  transform: translateY(-50%);
  width: rem(20);
}

.field-info {
  color: var(--blue_dark);
  font-size: rem(14);
  letter-spacing: 0;
  line-height: rem(21);
  margin-top: rem(8);
  text-align: left;
}

.inline-subtitle {
  color: #A5A5A5;
  font-weight: normal;
  margin-left: rem(10);
}
</style>
