import BaseRuleField from '@/utils/yo-validator/fields/BaseRuleField'
import LazyRuleField from '@/utils/yo-validator/fields/LazyRuleField'

const RULE_NAME_CONFIRMATION = 'passwordConfirm'
const EVENT_BLUR = 'blur'

export default class LazyRuleFieldProgress extends BaseRuleField {
  constructor({ id, formId, fieldName, rules, type }) {
    super({ id, formId, fieldName, rules, type })
    this.listeners = {}
  }

  setFieldData({ form, target }) {
    super.setFieldData({ formData: form.formData })
    // bind handlers for validation
    if (this.setLazyValidate(target)) {
      /** here "mutate" the LazyRuleFieldProgress to avoid the checks in "setLazyValidate" function executed every time **/
      this.removeAllHandlers()
      form.fields[this.id] = new LazyRuleField(form.fields[this.id])
    }
  }

  /** function to set field data for single field **/
  setFieldDataByTargetData({ parent, target, data }) {
    super.setFieldData({ formData: data })
    // bind handlers for validation
    if (this.setLazyValidate(target)) {
      /** here "mutate" the LazyRuleFieldProgress to avoid the checks in "setLazyValidate" function executed every time **/
      this.removeAllHandlers()
      parent.fields[this.id] = new LazyRuleField(parent.fields[this.id])
    }
  }

  removeAllHandlers() {
    // remove all handlers to avoid duplicates
    for (const targetKey in this.target) {
      if (this.target.hasOwnProperty(targetKey)) {
        const target = this.target[targetKey]
        target.removeEventListener(EVENT_BLUR, this.listeners[target.name])
      }
    }
  }

  setLazyValidate(target) {
    // set event target
    if (target && target.name && !this.target[target.name]) {
      /** here allow multiple is for the use of password confirm, in this special case 1 field 2 targets **/
      this.target[target.name] = target
      /** keep an original reference of the handler for later removal **/
      this.listeners[target.name] = super.validateRules.bind(this)
      this.target[target.name].addEventListener(EVENT_BLUR, this.listeners[target.name])
    }
    // check if the handlers are bound already
    const total = this.rules.includes(RULE_NAME_CONFIRMATION) ? 2 : 1
    return Object.keys(this.target).length > total - 1
  }
}
